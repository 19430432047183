<div class="row p-3 p-md-4" *ngIf="!success_transaction">
  <app-loader *ngIf="loader"></app-loader>
  <div class="payment-box col-12 col-lg-6 mb-4">
  
    <h1 class="mb-4">Selecciona método de pago</h1>

    <div class="d-flex flex-wrap w-100 mb-4">
      <div class="form-check me-3">
        <input class="form-check-input" value="card" type="radio" name="flexRadioDefault" id="flexRadioDefault1" [(ngModel)]="type_payment">
        <label class="form-check-label" for="flexRadioDefault1">
          Tarjeta de crédito
        </label>
      </div>
      <div class="form-check me-3">
        <input class="form-check-input" value="oxxo" type="radio" name="flexRadioDefault" id="flexRadioDefault2" [(ngModel)]="type_payment">
        <label class="form-check-label" for="flexRadioDefault2">
          Oxxo
        </label>
      </div>
      <div class="form-check me-3">
        <input class="form-check-input" value="transfer" type="radio" name="flexRadioDefault" id="flexRadioDefault2" [(ngModel)]="type_payment">
        <label class="form-check-label" for="flexRadioDefault2">
          Transferencia
        </label>
      </div>
    </div>
    
    <div *ngIf="type_payment === 'card'">
      <div class="mb-4">
          <label for="card-number">Numero de tarjeta</label>
          <div id="card" class="card-number"></div>
      </div>
    
      <div class="mb-4">
          <label for="name_user">Nombre del titular</label>
          <input id="name_user" placeholder="Juan Perez" class="card-number w-100" type="text" [(ngModel)]="name_user" />
      </div>
    
      <div class="row mb-4">
          <div class="col-12 col-md-6 mb-4 mb-md-0">
              <label for="exp">fecha de vencimiento</label>
              <div id="exp" class="card-number"></div>
          </div>
          <div class="col-12 col-md-6">
              <label for="cvv">CVV</label>
              <div id="cvv" class="card-number"></div>
          </div>
      </div>
    
      <div class="mb-4">
          <label for="cp">Código postal</label>
          <input id="cp" placeholder="99933" class="card-number w-100" type="text" [(ngModel)]="cp"/>
      </div>
    </div>

    <div *ngIf="type_payment === 'oxxo'">
      <form [formGroup]="oxxo_form" class="row">
        <div class="col-12 mb-4">
          <label for="card-number">Nombre*</label>
          <input type="text" placeholder="Juan Perez" class="card-number w-100" formControlName="name"/>
        </div>
        <div class="col-12 mb-4">
          <label for="card-number">Email*</label>
          <input type="text" placeholder="email@emai.com" class="card-number w-100" formControlName="email"/>
        </div>
      </form>
    </div>

    <div *ngIf="type_payment === 'transfer'">
      <div>
        Para realizar tu pago por transferencia bancaria, comunícate con nosotros al WhatsApp <strong>+52 446 117 6926</strong> y te brindaremos los detalles necesarios. ¡Estamos aquí para ayudarte! 
        <a class="btn btn-login btn-rounded w-100 mt-3" href="https://wa.me/14461176926?text=%C2%A1Hola!%20Quiero%20hacer%20una%20compra%20por%20transferencia." target="_blank">Abrir Whatsapp</a>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-6 mb-4">
    <div class="card-blue-container mb-3">
      <div class="card-blue-header text-center">
        <h2>RESUMEN</h2>
      </div>
      <div class="p-4 bg-white">
        <div *ngFor="let item of memberships; let i = index" class="d-flex align-items-center w-100 justify-content-between flex-wrap" [ngClass]="{'mb-5': i < memberships.length - 1}">
          <div>

            <h5>{{item.name}}{{i + 1}}</h5>
            <h3>{{item.alternative_name}}</h3>
            <h5>{{item.per_price}}</h5>
          </div>
          <app-amount [membership]="item"></app-amount>
        </div>

        <div class="col-12 mt-4 mb-5">
          <label for="card-number">Código del vendedor</label>
          <input type="text" placeholder="" class="card-number w-100" [(ngModel)]="reference_seller"/>
        </div>

        <div class="d-flex justify-content-between mb-4">
          <h5>Pagas hoy</h5>
          <h3>${{separatebycomma(getTotal().toString())}}</h3>
        </div>

        <div class="d-flex justify-content-between align-items-center" *ngIf="getNextTotalPaymentCase() > 0">
          <div class="d-flex flex-column">
            <h5>Siguiente pago</h5>
            <p>{{ getFutureDateInSevenMonths() }}</p>
          </div>
          <h3>$ {{ separatebycomma(getNextTotalPaymentCase().toString()) }}</h3>
        </div>
      </div>
    </div>
    <button id="button" (click)="type_payment === 'card' ? handleBuy() : buyOxxo()" [disabled]="!handleDisabledButton()" class="btn btn-login btn-rounded w-100">REALIZAR COMPRA</button>
  </div>
</div>

<div *ngIf="success_transaction" class="row p-3 p-md-4 d-flex justify-content-center">
  <div class="col-12 col-lg-6 mb-4">
    <div class="card-blue-container mb-3">
      <div class="card-blue-header text-center">
      </div>
      <div class="p-4 bg-white">
        <h1>¡PAGO REALIZADO CON ÉXITO!</h1>
        <h4>Revisa tu correo electrónico donde podrás encontrar la confirmación de tu compra.</h4>
        <button class="btn btn-login btn-rounded w-100 mt-3" [routerLink]="['/memberships/list']">Ir a ver las membresías compradas</button>
      </div>
  </div>
</div>

